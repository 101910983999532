import CurrencyCode from './models/currency-code';
import DeviceInfo from './models/device-info';
import WrappingState from './models/wrapping-state';
import CountryCode from './models/country-code';
import Platform from './models/platform';
import LanguageCode from './models/language-code';

export default class BoltBootstrapper {
    wrappingState?: WrappingState;
    deviceInfo?: DeviceInfo;

    get defaultWrappingState(): WrappingState {
        return {
            wrappingUrl: 'NOT USED ON WEB',
            configurationUrl: '',
            isPreviewMode: false,
            amount: 100,
            currencyCode: CurrencyCode.DKK,
            recipientName: 'Doc Brown',
            giverName: 'Marty Mcfly',
            giverProfileImageUrl: '',
            giftItemImageUrl: '',
            giftItemTitle: '',
            greetingText: 'Hello Doc!',
            customData: '',
        };
    }

    get defaultDeviceInfo(): DeviceInfo {
        return {
            languageCode: LanguageCode.DA,
            countryCode: CountryCode.DK,
            platform: Platform.Web,
            appVersion: '5.21.0',
            screenHeight: window.screen.height,
            safeAreaTop: 0,
            safeAreaBottom: 0,
        };
    }

    constructor(
        modifyWrappingState?: (wrappingState: WrappingState) => WrappingState,
        modifyDeviceInfo?: (deviceInfo: DeviceInfo) => DeviceInfo
    ) {
        this.wrappingState =
            (modifyWrappingState && modifyWrappingState(this.defaultWrappingState)) ||
            this.defaultWrappingState;

        this.deviceInfo =
            (modifyDeviceInfo && modifyDeviceInfo(this.defaultDeviceInfo)) ||
            this.defaultDeviceInfo;
    }

    launch() {
        document.getElementsByTagName('html')[0].innerHTML = this.html();

        (globalThis as any).start = () => {
            const wrappingState = (document.getElementById('state') as HTMLTextAreaElement).value;
            const encodedWrappingState = encodeURIComponent(wrappingState);

            const deviceInfo = (document.getElementById('deviceInfo') as HTMLTextAreaElement).value;
            const encodedDeviceInfo = encodeURIComponent(deviceInfo);

            const origin = window.location.origin;

            let path = window.location.pathname;
            path = path.replace(/^\/*/, ''); // Trim start for forward slashes
            path = path.replace(/\/*$/, ''); // Trim end for forward slashes

            const enrichedWrappingUrl = `${origin}/${path}?state=${encodedWrappingState}&deviceInfo=${encodedDeviceInfo}`;
            window.location.href = enrichedWrappingUrl;
        };

        for (let element of document.getElementsByTagName('textarea')) {
            const textarea = element as HTMLTextAreaElement;

            const calcHeight = () => {
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 'px';
            };

            textarea.addEventListener('oninput', calcHeight);
            calcHeight();
        }
    }

    css(): string {
        return `
        h1 {
            text-align: center;
        }

        textarea {
            width: 100%;
        }

        button {
            width: 100%;
            height: 50px;
        }
        `;
    }

    html(): string {
        const title = '⚡️ Bolt Boostrapper ⚡️';

        return `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8" />
                <title>${title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </head>
            <style>
                * {
                    font-family: sans-serif;
                }

                ${this.css()}
            </style>
            <body>
                <h1>${title}</h1>
                <b>State</b><br />
                <textarea id="state">${JSON.stringify(this.wrappingState || {}, null, 2)}</textarea>
                <br /><br />
                <b>Device Info</b><br />
                <textarea id="deviceInfo"">${JSON.stringify(
                    this.deviceInfo || {},
                    null,
                    2
                )}</textarea>
                <br />
                <button onclick="start()">Start</button>
            </body>
        </html>
        `;
    }
}
