export class BoltEvent {
    private listeners: (() => void)[] = [];

    listen = (callback: () => void): void => {
        this.listeners = [...this.listeners, callback];
    };

    invoke = (): void => {
        this.listeners.forEach((listener) => {
            listener();
        });
    };

    clearListeners = () => (this.listeners = []);
}

export class BoltEventWithResult<T> {
    private listeners: ((result: T) => void)[] = [];

    listen = (callback: (result: T) => void): void => {
        this.listeners = [...this.listeners, callback];
    };

    invoke = (result: T): void => {
        this.listeners.forEach((listener) => {
            listener(result);
        });
    };

    clearListeners = (): void => {
        this.listeners = [];
    };
}
