import Bolt from 'bolt';
import WrappingConfiguration from './models/wrapping-configuration';
import TextConfiguration from 'bolt/dist/models/text-configuration';
import TextConfigurationElementType from 'bolt/dist/models/text-configuration-element-type';
import 'bolt/dist/extensions/html-element.extensions';

let _wrappingConfiguration: WrappingConfiguration;
let _screenHeight = 400;
let _safeAreaTop = 90;
let _screenWidth = 384;

window.onload = () => {
    Bolt.startOrLaunchBootstrapper((wrappingState) => ({
        ...wrappingState,
        configurationUrl: 'game/configuration.json',
    }));

    listenToBolt();

    Bolt.startLoading();

    Bolt.utils.setTexts(Bolt.deviceInfo.languageCode, TEXT_CONFIGURATION);
    document.getElementById('accessibilityContinueAction')!!.addOnClickListener(()=>{
        showOverlay();
        Bolt.finish();
    });
    setTimeout(() => {
        // Time to resize webview
        initiateGame();    
    },500);
    
};

const initiateGame = async () => {
    try {
        _wrappingConfiguration = await Bolt.utils.getWrappingConfiguration();       
    } catch (error) {
        return;
    }

    // @ts-ignore
    if (!("screenHeight" in Bolt.deviceInfo)==0 && !("safeAreaTop" in Bolt.deviceInfo)==0) {
        _screenHeight = Bolt.deviceInfo.screenHeight;
        _safeAreaTop = Bolt.deviceInfo.safeAreaTop;
    }
    const aspectRatio = 1.9375
    // @ts-ignore
    _screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;    
    // @ts-ignore
    setGameHeight(_screenWidth * aspectRatio);    
    // @ts-ignore
    setGameTopMargin(_safeAreaTop);    
    
    document.getElementById('gameStyle')!.setAttribute('href', _wrappingConfiguration.gameStyleUrl);
    document
        .getElementById('gameScript')!
        .setAttribute('src', _wrappingConfiguration.gameScriptUrl);

    setupInfoDialog();
};

const setupInfoDialog = () => {
    if (Bolt.state.isPreviewMode) {
        document.getElementById('infoDialogTextGiver')!.classList.remove('hidden');
        document.getElementById('infoDialogCTA')!.classList.add('button','button-link');
    }
    else {
        document.getElementById('infoDialogTextReceiver')!.classList.remove('hidden');
        document.getElementById('infoDialogCTA')!.classList.add('button');
    }        
    document.getElementById('infoDialog')!.style.height = `${_screenHeight}px`;    
    document.getElementById('infoDialogCTA')!.addOnClickListener(()=>{
        document.body.style.backgroundColor = _wrappingConfiguration.backgroundColor;
        // @ts-ignore
        startGame();
    });
};

const hideInfoDialog = ((globalThis as any).hideInfoDialog = (): void => {
    setTimeout( () => {
        document.getElementById("infoDialog")!.classList.add('offscreen');        
    },200);
});

var firstTime = true;
const stopLoading = ((globalThis as any).stopLoading = (): void => {
    if(firstTime) {
        // @ts-ignore
        muteSound();
        firstTime = false;        
    }
    Bolt.stopLoading();
});

const listenToBolt = (): void => {
    Bolt.onPause.listen(() => {        
        // @ts-ignore
        ig.game.pauseGame();
    });

    Bolt.onResume.listen(() => {
        // @ts-ignore
        ig.game.resumeGame();
    });

    Bolt.onRestart.listen(() => {
        // @ts-ignore
        ig.game.changePage(LevelGameplay);
    });

    Bolt.onFinish.listen(() => {
        showOverlay();        
        // @ts-ignore
        pauseGame();
    });

    Bolt.onMovedToEnd.listen(() => {
        // @ts-ignore
        pauseGame();
        showOverlay();        
    });
};

const showOverlay = ((globalThis as any).showOverlay = (): void => {
    let overlay = document.getElementById('overlay');
    overlay!.classList.remove('hidden');
    overlay!.classList.add('fade-in');
});

const TEXT_CONFIGURATION: TextConfiguration[] = [
    {
        elementId: 'accessibilityContinueAction',
        elementType: TextConfigurationElementType.Property,
        elementTypeName: 'innerText',
        DA: 'Fortsæt til kortet',
        FI: 'Jatka onnittelukorttiin',
        EN: 'Continue to the greeting card',
    },
    {
        elementId: 'ariaDetails',
        elementType: TextConfigurationElementType.Property,
        elementTypeName: 'innerText',
        DA: 'Denne skærm indeholder en indpakning i form af et spil.',
        FI: 'Tämä näyttö sisältää pelipaketoinnin.',
        EN: 'This screen contains a game wrapping.',
    },
    {
        elementId: 'infoDialogTextGiver',
        elementType: TextConfigurationElementType.Property,
        elementTypeName: 'innerText',
        DA: 'Din gave pakkes ind i et lille spil. Vil du selv prøve det?',
        FI: 'Lahjasi paketoidaan pieneksi peliksi. Haluatko kokeilla sitä?',
        EN: 'Your gift will be wrapped in a small game. Would you like to try it?',
    },
    {
        elementId: 'infoDialogTextReceiver',
        elementType: TextConfigurationElementType.Property,
        elementTypeName: 'innerText',
        DA: 'Du har modtaget en gave, som er pakket ind i et lille spil. God fornøjelse!',
        FI: 'Sait lahjan. Pääset avaamaan lahjan pelaamalla pienen pelin.',
        EN: 'You received a gift. Please complete a small game before opening it.',
    },
    {
        elementId: 'infoDialogCTA',
        elementType: TextConfigurationElementType.Property,
        elementTypeName: 'innerText',
        DA: 'Start spillet',
        FI: 'Pelaamaan',
        EN: 'Let\'s play',
    },
];
