export interface BoltError {
    code: number;
    message: string;
}

export const BOLT_ERRORS = {
    UNKNOWN: { code: 0, message: 'Unknown error' },
    CUSTOM: { code: 10, message: ''},
    CONFIGURATION_NOT_LOADED: { code: 130, message: 'Configuration failed to load.' },    
};

export const isBoltError = (error: any): error is BoltError => {
    return error !== undefined && error.code !== undefined && error.message !== undefined;
};
