import BoltFramework from './bolt-framework';
import CountryCode from './models/country-code';
import LanguageCode from './models/language-code';
import PlatformInfo from 'platform';
import OS from './models/os';
import Bolt from './index';
import TextConfiguration from './models/text-configuration';
import TextConfigurationElementType from './models/text-configuration-element-type';
import { BOLT_ERRORS } from './models/bolt-error';

export default class BoltUtils {
    private bolt: BoltFramework;
    private _remainingTimerSeconds = 0;

    constructor(bolt: BoltFramework) {
        this.bolt = bolt;
    }

    get isProduction(): boolean {
        return window.location.hostname === 'cdn.mobilepay.dk';
    }

    get os(): OS {
        return {
            platform: Bolt.deviceInfo.platform,
            platformVersion: PlatformInfo.os?.version || '',
            browserVersion: PlatformInfo.version || '',
        };
    }

    get remainingTimeSecs(): number {
        return this._remainingTimerSeconds;
    }

    defaultLanguageCode = (): LanguageCode => {
        switch (this.bolt.deviceInfo.countryCode) {
            case CountryCode.DK:
                return LanguageCode.DA;
            case CountryCode.FI:
                return LanguageCode.FI;
        }
    };

    formatAmount = (amount: number, numberOfFractionDigits: number): string => {
        const defaultLanguageCode = this.defaultLanguageCode().toLowerCase();
        const countryCode = this.bolt.deviceInfo.countryCode.toUpperCase();
        const currencyCode = this.bolt.state.currencyCode.toUpperCase();

        const formatter = new Intl.NumberFormat(`${defaultLanguageCode}-${countryCode}`, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: numberOfFractionDigits,
            maximumFractionDigits: 2,
        });

        return formatter.format(amount);
    };

    isAppVersionGreaterThanOrEqualTo = (appVersion: string): boolean => {
        // Official semantic versioning RegEx
        // https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
        const appVersionRegex =
            /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

        const appVersionMatches = appVersion.match(appVersionRegex)?.slice(1);
        const currentAppVersionMatches = this.bolt.deviceInfo.appVersion
            .match(appVersionRegex)
            ?.slice(1);

        if (!appVersionMatches || !currentAppVersionMatches)
            throw new Error(
                `Unexpected AppVersion format, got ${appVersion} ${this.bolt.deviceInfo.appVersion}`
            );

        const [major, minor, patch] = appVersionMatches.map((comp) => +comp);
        const [currentMajor, currentMinor, currentPatch] = currentAppVersionMatches.map(
            (comp) => +comp
        );

        return (
            currentMajor > major ||
            (currentMajor === major && currentMinor > minor) ||
            (currentMajor === major && currentMinor === minor && currentPatch >= patch)
        );
    };

    getWrappingConfiguration = async <T>(retryAttempts = 5): Promise<T> => {
        let url = this.bolt.state.configurationUrl;

        if (url.indexOf('?') === -1) {
            url += '?';
        } else {
            url += '&';
        }

        url += `cacheVersion=${Date.now()}`;

        try {
            const configResponse = await fetch(url);
            return (await configResponse.json()) as T;
        } catch (error) {
            if (retryAttempts < 1) {
                Bolt.error(BOLT_ERRORS.CONFIGURATION_NOT_LOADED);
                throw error;
            } else {
                const retryFetchConfigurationTimeMs = 2000;
                return new Promise((resolve, _) => {
                    setTimeout(async () => {
                        const wrappingConfiguration = await this.getWrappingConfiguration<T>(
                            retryAttempts - 1
                        );
                        resolve(wrappingConfiguration);
                    }, retryFetchConfigurationTimeMs);
                });
            }
        }
    };

    setTexts = (languageCode: LanguageCode, textConfiguration: TextConfiguration[]): void => {
        document.getElementsByTagName('html')[0].lang = languageCode.toLowerCase();

        textConfiguration.forEach((obj) => {
            if (obj.elementTypeName === 'innerHTML') return;

            let element = document.getElementById(obj.elementId) as HTMLElement;

            if (element) {
                if (obj.elementType === TextConfigurationElementType.Property) {
                    // @ts-ignore
                    element[obj.elementTypeName] = obj[languageCode];
                } else {
                    element.setAttribute(obj.elementTypeName, obj[languageCode]);
                }
            }
        });
    };

    startTimer = (durationSecs = 60): void => {
        this._remainingTimerSeconds = durationSecs;
        const timerInterval = setInterval(() => {
            if (this._remainingTimerSeconds === 0)
                clearInterval(timerInterval);
            else
                this._remainingTimerSeconds--;
        }, 1000);
    };
}
