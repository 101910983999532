import BoltFramework from './bolt-framework';
import Platform from './models/platform';

declare var Android: any;
declare var webkit: any;

export default class BoltNativeAppInterface {
    private bolt: BoltFramework;

    constructor(bolt: BoltFramework) {
        this.bolt = bolt;
    }

    invoke = (functionName: string, data?: {}, shouldLog = true): void => {
        const loggingSuffix = data ? ` with data ${JSON.stringify(data)}` : '';

        if (shouldLog) this.bolt.log.info(`Calling app function ${functionName}${loggingSuffix}`);

        try {
            switch (this.bolt.deviceInfo.platform) {
                case Platform.Android:
                    if (data) Android[functionName](...Object.values(data));
                    else Android[functionName]();
                    break;

                case Platform.iOS:
                    webkit.messageHandlers[functionName].postMessage(data || {});
                    break;

                default:
                    break;
            }
        } catch (error) {
            if (shouldLog)
                this.bolt.log.error(`Failed to call app function ${functionName}${loggingSuffix}`);
        }
    };
}
