import BoltFramework from './bolt-framework';

export default class BoltLogger {
    private bolt: BoltFramework;

    private get logPrefix(): string {
        return 'BOLT ⚡️:';
    }

    constructor(bolt: BoltFramework) {
        this.bolt = bolt;
    }

    info = (message: string): void => {
        console.log(this.logPrefix, message);

        this.bolt.nativeAppInterface.invoke(
            'log',
            {
                message: message,
                severity: 'info',
            },
            false
        );
    };

    warning = (message: string): void => {
        console.warn(this.logPrefix, message);

        this.bolt.nativeAppInterface.invoke(
            'log',
            {
                message: message,
                severity: 'warning',
            },
            false
        );
    };

    error = (message: string): void => {
        console.error(this.logPrefix, message);

        this.bolt.nativeAppInterface.invoke(
            'log',
            {
                message: message,
                severity: 'error',
            },
            false
        );
    };
}
