import WrappingState from './models/wrapping-state';
import { BoltEvent, BoltEventWithResult } from './bolt-event';
import BoltLogger from './bolt-logger';
import BoltUtils from './bolt-utils';
import DeviceInfo from './models/device-info';
import BoltNativeAppInterface from './bolt-native-app-interface';
import BoltBootstrapper from './bolt-bootstrapper';
import { BoltError, BOLT_ERRORS } from './models/bolt-error';

export default class BoltFramework {
    onResume = new BoltEvent();
    onPause = new BoltEvent();
    onMovedToEnd = new BoltEvent();
    onRestart = new BoltEvent();
    onStateChanged = new BoltEventWithResult<{
        previousState?: WrappingState;
        newState: WrappingState;
    }>();
    onFinish = new BoltEvent();

    state!: WrappingState;
    deviceInfo!: DeviceInfo;
    isPaused: Boolean = false;

    log = new BoltLogger(this);
    utils = new BoltUtils(this);
    nativeAppInterface = new BoltNativeAppInterface(this);

    constructor() {
        this.onPause.listen(() => {
            this.isPaused = true;
        });

        this.onResume.listen(() => {
            this.isPaused = false;
        });
    }

    start = (): void => {
        try {
            const stateParamMatches = location.search.match(/\?state=(.*)\&/);
            const stateParam = stateParamMatches && stateParamMatches[1];
            if (!stateParam) throw new Error('State query parameter is missing');

            const deviceInfoMatches = location.search.match(/\&deviceInfo=(.*)/);
            const deviceInfoParam = deviceInfoMatches && deviceInfoMatches[1];
            if (!deviceInfoParam) throw new Error('Device info query parameter is missing');

            const decodedStateParam = decodeURIComponent(stateParam);
            const decodedDeviceInfoParam = decodeURIComponent(deviceInfoParam);

            // Since we don't know the app version at this point in time
            // we first try parsing with AppVersion < 5.21.0 encoding
            // if that fails we return to the new parsing format
            // TODO: Remove when minimum app version is 5.21.0 (PAY-116567)
            try {
                this.state = JSON.parse(atob(decodedStateParam)) as WrappingState;
                this.deviceInfo = JSON.parse(atob(decodedDeviceInfoParam)) as DeviceInfo;
            } catch (error) {
                this.state = JSON.parse(decodedStateParam) as WrappingState;
                this.deviceInfo = JSON.parse(decodedDeviceInfoParam) as DeviceInfo;
            }

            this.deviceInfo.screenHeight ??= screen.height;
            this.deviceInfo.safeAreaTop ??= 0;
            this.deviceInfo.safeAreaBottom ??= 0;

            this.onStateChanged.invoke({
                previousState: undefined,
                newState: this.state,
            });

            this.log.info(`Parsed wrapping data from ${window.location.search}`);
        } catch (error) {
            this.log.error(
                `Failed to parse wrapping data from ${
                    window.location.search
                } with error ${JSON.stringify(error)}`
            );

            throw error;
        }
    };

    startOrLaunchBootstrapper = (
        modifyWrappingState?: (wrappingState: WrappingState) => WrappingState,
        modifyDeviceInfo?: (deviceInfo: DeviceInfo) => DeviceInfo
    ): void => {
        try {
            this.start();
        } catch (error) {
            if (this.utils.isProduction) return;
            new BoltBootstrapper(modifyWrappingState, modifyDeviceInfo).launch();
        }
    };

    finish = (): void => this.nativeAppInterface.invoke('finish');
    startLoading = (): void => this.nativeAppInterface.invoke('startLoading');
    stopLoading = (): void => this.nativeAppInterface.invoke('stopLoading');

    error = (error: BoltError, data?: { [key: string]: any }): void => {
        if (!error || !error.code) error = BOLT_ERRORS.UNKNOWN;

        let message = error.message || BOLT_ERRORS.UNKNOWN.message;

        const dataString =
            data &&
            Object.entries(data)
                .filter((x) => x[1] !== undefined && x[1] !== null)
                .map(([key, value]) => {
                    return `${key}: ${value}`;
                })
                .join(', ');

        if (dataString) message += ` (${dataString})`;

        return this.nativeAppInterface.invoke('error', {
            code: error.code,
            message: message,
        });
    };

    setState = (state: string): void => {
        // TODO: Remove when minimum app version is 5.21.0 (PAY-116567)
        if (!this.utils.isAppVersionGreaterThanOrEqualTo('5.21.0')) {
            state = atob(state);
        }

        const previousState = this.state;
        const escapedState = state.replaceAll('\n', '\\n');

        this.state = JSON.parse(escapedState) as WrappingState;
        this.onStateChanged.invoke({ previousState, newState: this.state });
    };

    moveToEnd = (): void => {
        this.onMovedToEnd.invoke();
    };

    restart = (): void => {
        this.onRestart.invoke();
    };
}
