declare global {
    interface HTMLElement {
        addOnClickListener(
            callback: (this: HTMLElement, ev: TouchEvent | MouseEvent) => any,
            options?: boolean | AddEventListenerOptions | undefined
        ): () => void;
    }

    interface Document {
        addOnClickListener(
            callback: (this: Document, ev: TouchEvent | MouseEvent) => any,
            options?: boolean | AddEventListenerOptions | undefined
        ): () => void;
    }
}

const platformAwareClickEventName = () => ('ontouchstart' in window ? 'touchstart' : 'click');

HTMLElement.prototype.addOnClickListener = function (callback, options) {
    this.addEventListener(platformAwareClickEventName(), callback, options);

    return () => {
        this.removeEventListener(platformAwareClickEventName(), callback, options);
    };
};

Document.prototype.addOnClickListener = function (callback, options) {
    this.addEventListener(platformAwareClickEventName(), callback, options);

    return () => {
        this.removeEventListener(platformAwareClickEventName(), callback, options);
    };
};

export {};
